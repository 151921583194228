.codes-page {
  position: relative;
  .padding(0,0,50,0);
  .line-l-t {
    top:0;
    left:0;
    position: absolute;
    .width(392);
    @media @mobile-xs {
      width:11rem;
    }
  }
  .line-r-b {
    position: absolute;
    right:0;
    bottom:0;
    .width(284);
    height: auto;
    @media @mobile-xs {
      left: auto;
      right:0;
      .width(150);
    }
  }
  .text-block {
    position: relative;
    overflow: hidden;
    .padding(65,0,50,0);
    .font-size(18);
    .line-height(36);
    color:@text;
    @media @mobile-xs {
      padding: 3rem 0;
    }
    &.gray-bg {
      background: #f9f9f9;
      .padding(80,0,40,0);
      @media @mobile-xs {
        padding: 3rem 0 2rem;
      }
    }
    h2 {
      .font-size(35);
      .line-height(60);
      font-weight: 700;
      .margin(0,0,30,0);
      color:@black;
      @media @mobile-xs {
        .font-size(28);
        .line-height(40);
      }
    }
    h3 {
      .font-size(22);
      .line-height(40);
      font-weight: 700;
      .margin(0,0,0,0);
      color:@black;
      @media @mobile-xs {
        .font-size(18);
        .line-height(30);
      }
    }
    .text-side {
      position: relative;
      z-index: 2;
    }
    .image-codes {
      //float: right;
      position: relative;
      .width(812);
      height: auto;
      z-index: 2;
      @media @tablet, @mobile {
        width:100%;
      }
      img {
        width:100%;
        height: auto;
        position: absolute;
        left:0;
        top:0;
        &.img-empty {
          position: static;
        }
      }
    }
    .img-code {
      position: relative;
      //.width(616);
      width:100%;
      .padding(52,52,0,52);
      z-index: 2;
      @media @mobile-xs {
        padding-top: 0;
      }
      img {
        width:100%;
        height: auto;
      }
    }
  }
}