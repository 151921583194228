.contact-page {
  position: relative;
  .line-l-t {
    position: absolute;
    left:0;
    top:0;
    .width(318);
    height: auto;
    @media @mobile-xs {
      left: auto;
      right:0;
      .width(150);
    }
  }
  .contact-info-side {
    .padding(70,0,0,0);
    position: relative;
    z-index: 2;
    @media @mobile {
      padding: 2rem 0;
    }
  }
  .contact-info {
    font-size: 1rem;
    line-height: 1.625rem;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    .margin(0,0,25,0);
    dt {
      width:2rem;
      color:@primary;
      .font-size(14);
      margin: 0;
    }
    dd {
      margin: 0;
      .font-size(16);
      .line-height(26);
      color:@text;
      a {
        color:@text;
        &:focus {
          text-decoration: underline;
          color:@primary;
        }
      }
      h2 {
        .font-size(16);
        .line-height(26);
        color:@black;
        font-weight: 700;
      }
      p {
        margin: 0;
      }
    }
  }
  .form-holder {
    position: relative;
    .margin(-60,0,0,0);
  //@include media-breakpoint-down(sm) {
  //  margin-top: 2rem;
  //}
    @media @mobile {
      margin: 0;
    }
    .form-shadow {
      position: absolute;
      left:0;
      top:0;
      width:100%;
      height: 90%;
      box-shadow:0 0 60px rgba(0,0,0,0.2);
      z-index: 1;
    }
    .form-inner {
      position: relative;
      z-index: 2;
      .padding(60,80,0,80);
      background:#fff url(../img/stripe.png) repeat-x;
      .font-size(16);
      .line-height(24);
      color:@text;
      @media @mobile {
        padding: 2rem 2rem;
      }
    //@include media-breakpoint-down(sm) {
    //  padding: 1.5rem;
    //}
    //  .section-heading {
    //    font-size: 1.250rem;
    //    margin-bottom: 0.1rem;
    //  }
    }
    h3 {
      .font-size(20);
      .line-height(30);
      font-weight: 700;
      color:@black;
      .margin(0,0,10,0);
    }
    .btn {
      .width(180);
      border-radius: 3px;
    //@include media-breakpoint-down(sm) {
    //  display: block;
    //  margin: 1.5rem auto 0;
    //}
    }
    .form-group {
      position: relative;
      .margin(0,0,25,0);
      .form-control {
        border:0;
        border-bottom:1px solid #e3e3e3;
        border-radius: 0;
        font-size: 1rem;
        //color:$text-color;
        box-shadow:none;
        &::placeholder {
          color:#b1b1b1;
        }
      }
      input.form-control {
        padding-right: 5rem;
      }
      textarea.form-control {
        height: 9.688rem;
        resize: none;
      }
      label {
        position: absolute;
        right:0;
        .bottom(8);
        margin: 0;
        .ttu;
        color:#b1b1b1;
        font-size: 0.750rem;
        font-weight: 700;
        @media @mobile {
          position: static;
        }
      }
    }
  }
  .image-section {
    //display: none;
  //@include media-breakpoint-up(md) {
    display: block;
    height: 20.125em;
    margin-top: -6rem;
    position: relative;
    background: url(../img/panorama.jpg) repeat-x 50% 50%;
    background-size: cover;
    @media @mobile {
      height: 11rem;

    }
  //}
  }
  .staff-block {
    .padding(80,0,80,0);
    overflow: hidden;
    @media @mobile {
      padding: 3rem 0;
    }
  //@include media-breakpoint-up(md) {
  //  padding: 4.375rem 0;
  //}
    h2 {
      color: @black;
      .font-size(35);
      .line-height(40);
      .margin(0,0,100,0);
      .text-center;
      font-weight: 700;
      @media @mobile {
        margin: 0 0 3rem 0;
      }
    }
    .staff-list {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    //@include media-breakpoint-down(sm) {
    //  flex-wrap: wrap;
    //  padding: 2rem 0 0;
    //}
      .item {
        text-align: center;
        font-size: 1rem;
        line-height: 1.125rem;
        width:25%;
        @media @mobile {
          width:100%;
          margin: 0 0 2rem;
        }
      //@include media-breakpoint-down(sm) {
      //  width:50%;
      //  margin: 0 0 3rem;
      //}
        .avatar {
          .width(140);
          .height(140);
          border-radius: 50%;
          margin: 0 auto 2rem;
          background-position: 50% 50%;
          background-size: cover;
          background-repeat: no-repeat;
        //@include media-breakpoint-down(sm) {
        //  width:6.250rem;
        //  height:6.250rem;
        //}
        }
        h4 {
          color: @black;
          .font-size(20);
          .line-height(26);
          .margin(0,0,20,0);
          font-weight: 700;
        }
        .sep {
          margin: 0 auto 1.250rem;
          background: @primary;
          .width(70);
          .height(2);
        }
        .position {
          .margin(0,0,10,0);
          display: block;
        }
        .mail {
          color: @primary;
          &:focus {
            color: @secondary;
          }
        }
      }
    }
  }
  .map-block {
    .height(400);
    overflow: hidden;
    position: relative;
  }
}