.row:before, .row:after {display: none !important;}
* {
	margin:0;
	padding: 0;
}
html,
body {
	color: @text;
	min-height: 100vh;
	background: @white;
	//overflow-x: hidden;
	.OS;
	line-height: 1.1;
	@media (min-width:1441px) {
		font-size: 16px;
	}
	@media (max-width:1440px) {
		font-size: 14px;
	}
	@media (max-width:1280px) {
		font-size: 12px;
	}
	@media (max-width:1024px) {
		font-size: 13px;
	}
	&.font-size-s {
		@media (min-width:1441px) {
			font-size: 14px;
		}
		@media (max-width:1440px) {
			font-size: 12px;
		}
		@media (max-width:1280px) {
			font-size: 10px;
		}
		@media (max-width:1024px) {
			font-size: 15px;
		}
	}
	&.font-size-m {
		@media (min-width:1441px) {
			font-size: 16px;
		}
		@media (max-width:1440px) {
			font-size: 14px;
		}
		@media (max-width:1280px) {
			font-size: 12px;
		}
		@media (max-width:1024px) {
			font-size: 13px;
		}
	}
	&.font-size-l {
		@media (min-width:1441px) {
			font-size: 18px;
		}
		@media (max-width:1440px) {
			font-size: 16px;
		}
		@media (max-width:1280px) {
			font-size: 14px;
		}
		@media (max-width:1024px) {
			font-size: 15px;
		}
	}
}
img {
	vertical-align: top;
}
a {
	color: @primary;
	text-decoration: none;
	&:hover {
		color: @primary;
		text-decoration: underline;
	}
}
.overlay {
	padding-top: 0;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow: hidden;
	z-index: 10;
	display:none;
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		z-index: 100;
	}
	&.show {
		display: block;
	}
}
.no-scroll {
	@media @mobile {
		position: fixed;
		width:100%;
		height: 100%;
		overflow: hidden;
	}
}
.container {
	max-width: 81.875rem;
}
.btn {
	.inline-b;
	.line-height(48);
	.ttu;
	background: @primary;
	color:@white;
	.font-size(16);
	font-weight: 700;
	border: 1px solid @primary;
	cursor: pointer;
	border-radius: 0.438rem;
	.padding(0,30,0,30);
	.transition(all 0.1s ease);
	outline: 0;
	white-space: nowrap;
	&:hover {
		border-color:@primary;
		background: fade(@primary, 90%);
		color:@white;
		text-decoration: none;
	}
	&:focus {
		box-shadow:none;
		outline: 1px dashed @primary;
		//background: #f6ab63;
	}
	&.btn-transparent {
		background: none;
		color:@primary;
	}
	&.btn-black {
		background: @black;
		border-color:@black;
		&:hover {
			background: fade(@black, 80%);
		}
	}
	&.btn-outline-black {
		background: none;
		color:@black;
		border-color:@black;
		&:hover {
			background: fade(@black, 10%);
		}
	}
	&.btn-outline-white {
		background: none;
		color:@white;
		border-color:@white;
		&:hover {
			background: fade(@white, 10%);
		}
	}
}

/* page-title start */
.page-title {
	.height(500);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	@media @mobile {
		height: 18rem;
	}
	.text {
		text-align: center;
		.padding(70,0,0,0);
		h1 {
			.ttu;
			color:@white;
			.font-size(50);
			.line-height(60);
			font-weight: 700;
			margin: 0;
			@media @mobile {
				.font-size(40);
				.line-height(50);
			}
		}
	}
}
/* page-title end */


/* loader start */
.loader {
	background-color: @white;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	top: 0;
	height: 100%;
	width: 100%;
	z-index: 900;
	.loader_inner {
		background: @secondary;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 999;
		width: 9.375rem;
		position: absolute;
		height: 9.375rem;
		left:50%;
		top:50%;
		margin: -4.688rem 0 0 -4.688rem;
		animation: loader 1.5s infinite ease;
		img {
			max-width:60%;
			height: auto;
		}
	}
}
@keyframes loader {
	0% {
		transform:rotateY(-360deg);
	}
}
@-webkit-keyframes loader-back {
	0% {
		transform:rotateY(-360deg);
	}
}
/* loader end */


div,
footer:focus {
	outline: none;
}
.custom-focus {
	&:focus {
		outline: 1px dashed @primary;
		@media @tablet, @mobile {
			outline: none;
		}
	}
}

@media @tablet-portrait, @mobile {
	//.wow {
	//	visibility: visible !important;
	//	-webkit-animation: none !important;
	//	-moz-animation: none !important;
	//	-o-animation: none !important;
	//	-ms-animation: none !important;
	//	animation: none !important;
	//}
}
