#header {
  background: transparent;
  z-index: 100;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  .padding(7, 0, 7, 0);
  color: #fff;
  .transition(350ms ease-in-out);

  &:focus {
    outline: none;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    .height(109);
    background: url(../img/shadow.png) repeat-x;
    background-size: auto 100%;
    .transition(350ms ease-in-out);
  }

  &.blue-bg {
    background: fade(@secondary, 90%);
    padding: 0;

    .logo {
      .width(180);
    }

    &:before {
      opacity: 0;
    }

    .header-inner {
      .height(70);
    }
  }

  .header-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .right-side {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .logo {
    position: relative;
    .width(297);
    .transition(350ms ease-in-out);
    flex-shrink: 0;
    z-index: 2;
    @media @tablet, @mobile {
      .width(180);
    }

    &:focus {
      outline: 1px dashed @primary;
    }

    img {
      display: block;
      width: 100%;
      height: auto;
      .transition(350ms ease-in-out);
    }
  }
  .nav-holder {
    width: 100%;
    position: relative;
    z-index: 2;
    @media @tablet-portrait, @mobile {
      position: fixed;
      left:-100%;
      top:0;
      height: 100%;
      width:80%;
      background: @secondary;
      padding: 1rem;
      .transition(350ms ease-in-out);
      &.show {
        left:0;
      }
    }
    @media @mobile-xs {
      width:100%;
    }
    .nav-logo {
      display: none;
      @media @tablet-portrait, @mobile {
        display: block;
        width: 10rem;
        height: auto;
        .pos-centerX;
        top:1rem;
      }
    }
    .login-drop {
      @media @tablet-portrait, @mobile {
        display: block;
        .pos-centerX;
        bottom:2rem;
      }
    }

    .nav {
      justify-content: center;
      @media @tablet-portrait, @mobile {
        flex-direction: column;
        align-items: center;
        padding:4rem 2rem;
        height: 100%;
      }
      li {
        .font-size(16);
        .ttu;
        .margin(0, 20, 0, 20);
        font-weight: 700;
        @media @tablet-portrait, @mobile {
          width:100%;
          margin: 0;
          border-top:1px solid rgba(255,255,255,0.2);
        }
        &:last-child {
          @media @tablet-portrait, @mobile {
            border-bottom:1px solid rgba(255,255,255,0.2);
          }
        }
        a {
          display: block;
          color: @white;
          @media @tablet-portrait, @mobile {
            padding: 1.5rem 1rem;
            text-align: center;
          }
          &:hover {
            text-decoration: none;
            color: @primary;
          }

          &:focus {
            outline: 1px dashed @primary;
          }
        }

        &.active {
          a {
            color: @primary;
          }
        }
      }
    }
  }
  .text-size {
    position: absolute;
    .right(20);
    .pos-centerY;
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: flex-end;
    .transition(350ms ease-in-out);
    @media @desktop-xl {
      position: static;
      transform: translateY(0);
      .margin(-5, 0, 0, 5);
    }
    li {
      padding: 0 0 0 1rem;
      @media (max-width: 767px) {
        padding: 0 0 0 0.5rem;
      }

      a {
        display: block;
        .CG;
        text-transform: uppercase;
        color: @white;
        position: relative;

        &:before {
          content: '';
          position: absolute;
          bottom: 0;
          height: 2px;
          width: 0;
          background: @primary;
          .transition(0.3s ease);
          .pos-centerX;
        }

        &.size-s {
          font-size: 1.216em;
          line-height: 21px;
          margin-bottom: 4px;

          &:before {
            bottom: 0px;
          }

          @media (max-width: 767px) {
            font-size: 1.125rem;
            &:before {
              bottom: 2px;
            }
          }
        }

        &.size-m {
          font-size: 1.976em;
          line-height: 33px;
          margin-bottom: 2px;
          @media (max-width: 767px) {
            font-size: 1.750rem;
            margin-bottom: 0px;
            &:before {
              bottom: 4px;
            }
          }
        }

        &.size-l {
          font-size: 2.737em;
          line-height: 45px;

          &:before {
            bottom: 0px;
          }

          @media (max-width: 767px) {
            font-size: 2.5em;
            &:before {
              bottom: 3px;
            }
          }
        }

        &.active {
          color: @primary;

          &:before {
            width: 100%;
          }
        }

        &:hover {
          color: @primary;
          text-decoration: none;
        }

        &:focus {
          color: @primary;

          &:before {
            width: 140%;
          }
        }
      }
    }
  }

  .login-drop {
    position: relative;
    .width(180);
    .transition(350ms ease-in-out);
    .login-btn {
      display: block;
      width: 100%;
      .line-height(40);
      border-radius: 3px;

      .fa-chevron-down {
        .font-size(15);
        position: absolute;
        .transition(350ms ease-in-out);
        .right(16);
        .top(14);
        left: auto;
      }

      &.active {
        box-shadow: none !important;

        .fa-chevron-down {
          .transform(rotate(-180deg));
        }
      }
    }

    .drop {
      display: none;
      position: absolute;
      right: 0;
      top: 108%;
      background: #fff;
      border-radius: 3px;
      width: 100%;
      box-shadow: 0 7px 7px rgba(0, 0, 0, 0.13);
      overflow: hidden;
      @media @tablet-portrait, @mobile {
        top: auto;
        bottom: 108%;
      }

      ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
          border-top: 1px solid #d3d3d3;

          &:first-child {
            border: 0;
          }

          a {
            display: block;
            padding: 0.81rem 0 0.81rem 1.2rem;
            font-size: 0.9375rem;
            white-space: nowrap;

            &:hover,
            &:focus {
              text-decoration: none;
              background: @primary;
              color: @white;
            }
          }
        }
      }
    }
  }
}

/*hamburger btn*/
.btn-lines {
  position: absolute;
  display: block;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: @white;
  content: "";
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.c-hamburger {
  display: none;
  //overflow: hidden;
  position: relative;
  .margin(0,0,0,32);
  z-index: 2;
  padding: 0;
  width: 26px;
  height: 20px;
  font-size: 0;
  text-indent: -9999px;
  appearance: none;
  border: none;
  cursor: pointer;
  -webkit-transition: all 0.3s ease 0.3s;
  transition: all 0.3s ease 0.3s;

  &:focus {
    outline: none;
  }

  &:before {
    position: absolute;
    content: '';
    left: -13px;
    top: -2px;
    width: 1px;
    height: 24px;
    background: #ffffff;
    -webkit-transition: all 0.3s ease 0.3s;
    transition: all 0.3s ease 0.3s;
  }

  @media @tablet-portrait, @mobile {
    display: block;
  }

  span {
    display: block;
    position: absolute;
    top: 9px;
    left: 0px;
    right: 0px;
    height: 2px;
    background: @white;
  }

  span::before {
    &:extend(.btn-lines);
    top: -7px;
  }

  span::after {
    &:extend(.btn-lines);
    bottom: -7px;
  }
}

.c-hamburger--htx {
  background-color: transparent;

  &.active {
    &:before {
      opacity: 0;
    }
    span {
      background: none;
    }

    span::before {
      top: 0;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      background: @white;
    }

    span::after {
      bottom: 0;
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      background: @white;
    }
  }
}

/*hamburger btn*/