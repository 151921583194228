.news-page {
  .padding(125,0,50,0);
  position: relative;
  overflow: hidden;
  .line-l-t {
    position: absolute;
    left:0;
    top:0;
    .width(313);
    height: auto;
    @media @mobile-xs {
      left: auto;
      right:0;
      .width(150);
    }
  }
  .line-r-b {
    position: absolute;
    right:0;
    bottom:0;
    .width(284);
    height: auto;
    @media @mobile-xs {
      left: auto;
      right:0;
      .width(150);
    }
  }
  .news-list {
    position: relative;
    z-index: 3;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    @media @tablet-portrait {
      justify-content: center;
    }
    @media @mobile {
      justify-content: center;
    }
    &:after {
      content: '';
      .width(520);
    }
    .item {
      .width(520);
      background: #f9f9f9;
      position: relative;
      .padding(0,0,76,0);
      border-bottom: 4px solid transparent;
      .margin(0,0,90,0);
      transition: all 0.3s ease;
      @media @mobile-xs {
        width:95%;
        margin: 0 0 4rem 5%;
      }
      &:hover {
        background: @white;
        border-color:@primary;
        border-radius:0 0 1.250rem 1.250rem;
        box-shadow:0 7px 32px rgba(0,0,0,0.25);
      }
      .image {
        .width(500);
        .height(300);
        overflow: hidden;
        border-radius: 1.250rem;
        box-shadow:0 18px 27px rgba(0,0,0,0.2);
        .margin(-41,49,35,-30);
        @media @mobile-xs {
          width:100%;
          .height(250);
          margin: -2rem 5% 2rem -5%;
        }
        a {
          display: block;
          height: 100%;
          transition: all 0.3s ease;
          background-position: 50% 50%;
          background-repeat: no-repeat;
          background-size: cover;
          &:hover,
          &:focus {
            transform: scale(1.1);
          }
        }
      }
      .inner {
        .padding(0,40,0,40);
        min-height: 12.692rem;
        @media @mobile-xs {
          .padding(0,25,0,25);
          min-height: 7rem;
        }
        h3 {
          .font-size(24);
          .line-height(40);
          font-weight: 700;
          margin: 0;
          @media @mobile-xs {
            .font-size(20);
            .line-height(26);
          }
          a {
            color: @black;
            transition: all 0.3s ease;
            &:hover {
              text-decoration: none;
              color:@primary;
            }
          }
        }
      }
      .btm {
        position: absolute;
        bottom: 0;
        left:0;
        width:100%;
        .height(76);
        border-top:1px solid #e3e3e3;
        border-top: 1px solid #e3e3e3;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .padding(0,25,0,40);
        color: @secondary;
        .font-size(14);
        .line-height(16);
        @media @mobile-xs {
          .padding(0,25,0,25);
        }
        .logo {
          max-height: 3rem;
          max-width: 9.5rem;
          height: auto;
          width: auto;
          margin: 0 1rem 0 0;
          @media @mobile-xs {
            max-height: 1.750rem;
            max-width: 6.5rem;
          }
        }
        span {
          position: relative;
          .padding(0,5,0,0);
          .margin(0,4,0,0);
          &:last-child {
            padding: 0;
            margin: 0;
            &:after {
              display: none;
            }
          }
          &:after {
            content: '';
            background:@secondary;
            border-radius: 50%;
            width: 0.188rem;
            height: 0.188rem;
            position: absolute;
            right: 0;
            top: 0.438rem;
          }
        }
      }
    }
  }
}
.news-article {
  .font-size(18);
  .line-height(36);
  .padding(80,0,50,0);
  position: relative;
  overflow: hidden;
  @media @mobile-xs {
    padding: 3rem 0;
  }
  .line-l-t {
    position: absolute;
    left:0;
    top:0;
    .width(313);
    height: auto;
    @media @mobile-xs {
      left: auto;
      right:0;
      .width(150);
    }
  }
  .line-r-b {
    position: absolute;
    right:0;
    bottom:0;
    .width(284);
    height: auto;
    @media @mobile-xs {
      left: auto;
      right:0;
      .width(150);
    }
  }
  h2 {
    .text-center;
    .font-size(35);
    .line-height(65);
    font-weight: 700;
    color:@black;
    .margin(0,0,40,0);
    @media @mobile-xs {
      .font-size(26);
      .line-height(36);
    }
  }
  h3 {
    .font-size(26);
    .line-height(36);
    font-weight: 700;
    color:@black;
    .margin(0,0,20,0);
    @media @mobile-xs {
      .font-size(26);
      .line-height(36);
    }
  }
  .single-img {
    display: block;
    width:80%;
    height: auto;
    margin:0 auto 2rem;
    @media @mobile-xs {
     width:100%;
    }
  }
  .single-img-sm {
    display: block;
    max-width:100%;
    height: auto;
    margin:0 auto 2rem;
  }
  .caption {
    width:80%;
    margin:-2rem auto 2rem;
    color: #545454;
    font-size: 0.938rem;
    line-height: 1.563rem;
    padding: 1.2rem 1.5rem;
    background-color: #f9f9f9;
    font-weight: 400;
    @media @mobile-xs {
      width:100%;
    }
  }
  .article-figure {
    margin:2rem 3rem 2rem 0;
    float: left;
    width: 40%;
    figure {
      img {
        width:100%;
        height: auto;
      }
    }
  }
  .video-wrapper {
    margin: 2rem auto 2rem;
    width:80%;
    @media @mobile-xs {
      width:100%;
    }
    .video {
      position: relative;
      padding-bottom: 56.25%;
      padding-top: 25px;
      height: 0;
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
  p {
    margin: 0 0 1.250rem;
  }
  ul {
    margin: 0 0 1.250rem;
    list-style-position: inside;
    li {
      margin: 0;
      padding: 0 0 1.250rem;
    }
  }
  .author {
    display: flex;
    justify-content: center;
    align-items: center;
    .margin(0,0,40,0);
    color: @secondary;
    .font-size(16);
    .line-height(20);
    @media @mobile-xs {
      .padding(0,0,20,0);
    }
    .logo {
      max-height: 4rem;
      max-width: 11rem;
      height: auto;
      width: auto;
      margin: 0 1rem 0 0;
      @media @mobile-xs {
        max-height: 2.5rem;
        max-width: 8rem;
      }
    }
    span {
      position: relative;
      .padding(0,12,0,0);
      .margin(0,10,0,0);
      &:last-child {
        padding: 0;
        margin: 0;
        &:after {
          display: none;
        }
      }
      &:after {
        content: '';
        background:@secondary;
        border-radius: 50%;
        .width(5);
        .height(5);
        position: absolute;
        right: 0;
        top: 0.438rem;
      }
    }
  }
}