/* banner start */
.banner {
  position: relative;
  overflow: hidden;
  height: 100vh;
  @media @tablet-portrait {
    height: 34rem;
  }
  @media @mobile-xs {
    height: 30rem;
  }
  .video-holder {
    video {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      @media @mobile {
        margin-left: 0;
        top: -20px;
        height: ~"calc(100% + 20px)";
      }
      @media @min-aspect169 {
        & {
          height: 400%;
          top: -150%;
        }
      }

      @media @max-aspect169  {
        & {
          width: 400%;
          left: -150%;
        }
      }
    }
    &:before {
      content: '';
      position: absolute;
      .full;
      background: rgba(0,0,0,0.23);
      z-index: 4;
    }
  }
  .text {
    .pos-center;
    width:100%;
    .text-center;
    color:@white;
    .padding(16,16,16,16);
    z-index: 5;
    strong {
      display: block;
      .ttu;
      .font-size(50);
      .line-height(60);
      @media @mobile-xs {
        .font-size(24);
        .line-height(38);
      }
    }
  }
  .scroll-down {
    .pos-centerX;
    z-index: 5;
    .bottom(16);
    .width(27);
    .height(40);
    background: url(../img/svg/mouse.svg) no-repeat;
    background-size: contain;
    cursor: pointer;
    span {
      position: absolute;
      transform: rotate(-90deg);
      .top(-35);
      .left(-5);
      .font-size(14);
      .line-height(16);
      color:@white;
    }
  }
}
/* banner end */

/* info-block start */
.info-block {
  .padding(100,0,100,0);
  position: relative;
  @media @mobile {
    padding: 3rem 0;
  }
  .line-t-l {
    position: absolute;
    top:0;
    left:0;
    .width(410);
    @media @mobile {
      width:8.625rem;
    }
  }
  .line-b-r {
    position: absolute;
    right:0;
    bottom:0;
    .width(352);
    @media @mobile {
      width:9rem;
    }
  }
  .info-block-inner {
    .padding(0,90,0,90);
    .text-center;
    position: relative;
    z-index: 3;
    @media @mobile {
      padding: 0;
    }
  }
  h2 {
    .font-size(35);
    .line-height(60);
    font-weight: 700;
    .margin(0,0,60,0);
    color:@black;
    @media @tablet-portrait {
      .font-size(28);
      .line-height(38);
    }
    @media @mobile {
      .font-size(24);
      .line-height(34);
    }
  }
  .info-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media @tablet-portrait {
      justify-content: center;
    }
    @media @mobile {
      justify-content: center;
    }
    .item {
      border-radius: 1.250rem;
      overflow: hidden;
      background: @white;
      box-shadow:0 18px 27px rgba(0,0,0,0.2);
      color:@black;
      .width(285);
      .margin(0,0,30,0);
      .transition(all 0.3s ease);
      @media @tablet-portrait, @mobile {
        margin-left: 1rem;
        margin-right: 1rem;
      }
      &:hover {
        text-decoration: none;
        color:@primary;
        .image {
          em {
            transform: scale(1.1);
          }
        }
      }
      .image {
        display: block;
        .height(162);
        position: relative;
        overflow: hidden;
        em {
          display: block;
          height: 100%;
          width:100%;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: 50% 50%;
          .transition(all 0.3s ease);
        }
      }
      strong {
        .font-size(18);
        font-weight: 700;
        .height(62);
        display: flex;
        align-items: center;
        justify-content: center
      }
    }
  }
}
/* info-block end */

/* how works start */

.section-works {
  overflow: hidden;
  background-image: url(../img/how-it-works-bg.jpg);
  background-attachment: fixed;
  background-size: cover;
  background-position: 50% 50%;
  .padding(100,0,120,0);
  position: relative;
  @media @tablet-portrait, @mobile{
    background-attachment: scroll;
  }
  @media @mobile {
    padding: 3rem 0;
  }
  .section-works-inner-1,
  .section-works-inner-2 {
    display: block;
    position: absolute;
    left:0;
    top:0;
    width:100%;
    height: 100%;
    background-image: url(../img/bg-shapes-3.png);
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }
  .section-works-inner-2 {
    background-image: url(../img/bg-shapes-4.png);
  }
  .logo-img {
    width:14.1rem;
    height: auto;
  }
  .section-heading {
    font-size: 2.1875rem;
  }
  .text {
    text-align: center;
    font-size: 1.375rem;
    .padding(0,0,55,0);
    .font-size(24);
    .line-height(38);
    color:@white;
    @media @mobile {
      .font-size(18);
      .line-height(26);
      padding: 0 0 2rem;
    }
    strong {
      display: block;
      .font-size(35);
      .line-height(40);
      font-weight: 700;
      @media @mobile {
        .font-size(30);
        .line-height(35);
      }
    }
  }
  .work-mobile {
    img {
      display: block;
      margin: 0 auto;
      .width(536);
      @media @mobile-xs {
        width:90%;
      }
    }
  }
  .work-holder {
    margin: 0 -15px;
  }
  .work-inner {
    position: relative;
    .works-bg {
      width:100%;
      height: auto;
    }
    .hexagon-img {
      display: block;
      height: auto;
    }
    .block-inner {
      position: absolute;
      width:100%;
      height: 100%;
      left:0;
      top:0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .icon {
        margin-bottom: 0.5rem;
      }
      .ttl {
        text-transform: uppercase;
        font-size: 1.125rem;
        color:@white;
      }
    }
    .arrow {
      position: absolute;
      height: auto;
      max-width:none;
    }
    .title {
      position: absolute;
      left:0;
      top:260px;
      z-index: 3;
    }
    .district {
      position: absolute;
      left:8rem;
      top:2rem;
      .hexagon-img {
        width:13.938rem;
      }
      .block-inner {
        .icon {
          height: 2.82rem;
        }
      }
      .district-parents-arrow {
        width:47.938rem;
        top: 11.9rem;
        left: 2.3rem;
      }
      .district-parents-arrow-2 {
        width:44.625rem;
        top: -1.9rem;
        left: 11.7rem;
      }
      .district-police-arrow {
        width:8.125rem;
        top: 11.7rem;
        left: 6.9rem;
      }
      .district-school-arrow {
        width: 1.813rem;
        left: 11.4rem;
        top: 9rem;
      }
    }
    .school {
      position: absolute;
      top: 7.6rem;
      left: 18.3rem;
      .hexagon-img {
        width:18.563rem;
      }
      .block-inner {
        .icon {
          height: 4.5rem;
        }
        .ttl {
          font-size: 1.5rem;
          font-weight: 700;
        }
      }
      .school-police-arrow {
        width:0.813rem;
        left: 8.8rem;
        top: 15.8rem;
      }
      .school-teachers-arrow {
        width:3.125rem;
        left: 17.5rem;
        top: 8.2rem;
      }
      .parents-school-arrow {
        width:27.625rem;
        top: 13.2rem;
        left: 15.5rem;
      }
      .parents-school-arrow-2 {
        width:30.938rem;
        top: -6.2rem;
        left: 15.5rem;
      }
    }
    .police {
      position: absolute;
      top: 24.5rem;
      left: 20.5rem;
      .hexagon-img {
        width:13.938rem;
      }
      .block-inner {
        .icon {
          height: 2.82rem;
        }
      }
    }
    .teachers-block {
      position: absolute;
      top: 7.8rem;
      left: 36.1rem;
      width:15.125rem;
      height: 17rem;
      .teachers {
        position: absolute;
        &.teachers-1 {
          top:0;
          left:0;
        }
        &.teachers-2 {
          bottom:0;
          left:0;
        }
        &.teachers-3 {
          right:0;
          top:4rem;
        }
        .hexagon-img {
          width:10.250rem;
        }
        .block-inner {
          .icon {
            height: 2.63rem;
          }
        }
      }
      .teachers-students-arrow {
        width:1.938rem;
        top: 13.4rem;
        left: 8.2rem;
      }
      .teachers-students-arrow-2 {
        width:2.625rem;
        top: 5.8rem;
        left: 13.1rem;
      }
    }
    .students-block {
      position: absolute;
      top: 3.8rem;
      left: 50.2rem;
      width:13.3rem;
      height: 12.57rem;
      .students {
        position: absolute;
        &.students-1 {
          bottom:0;
          left:0;
        }
        &.students-2 {
          right: -0.1rem;
          top: 1.2rem;
        }
        &.students-3 {
          top:0;
          left:0.9rem;
        }
        .hexagon-img {
          width:8.313rem;
        }
        .block-inner {
          .icon {
            height: 2rem;
          }
          .ttl {
            padding-right: 4rem;
          }
        }
      }
      &.students-block-bottom {
        top: 21.0rem;
        left: 44.2rem;
        .students {
          &.students-4 {
            top:0;
            left:0;
          }
          &.students-5 {
            right: -0.3rem;
            top: 3.0rem;
          }
          &.students-6 {
            left:0.7rem;
            bottom:0;
          }
        }
      }
    }
    .parents-block {
      position: absolute;
      top: 2.9rem;
      left: 63.1rem;
      width:11.625rem;
      height: 13.500rem;
      .parents {
        position: absolute;
        &.parents-1 {
          top:0;
          left:0;
        }
        &.parents-2 {
          left: 0;
          bottom: -0.5rem;
        }
        &.parents-3 {
          top: 3rem;
          right: -0.5rem;
        }
        .hexagon-img {
          width:8.313rem;
        }
        .block-inner {
          .icon {
            height: 1.875rem;
          }
          .ttl {
            padding-left: 3.5rem;
          }
        }
      }
      &.parents-block-bottom {
        top: 21.0rem;
        left: 57rem;
      }
    }
  }
}


/* how works end */

/* news-block start */
.news-block {
  .padding(100,0,110,0);
  position: relative;
  overflow: hidden;
  @media @mobile {
    padding: 3rem 0;
  }
  .line-t-l {
    position: absolute;
    .left(28);
    top:0;
    .width(375);
    height: auto;
    @media @mobile {
      width:8rem;
    }
  }
  h2 {
    .font-size(35);
    .line-height(60);
    font-weight: 700;
    .margin(0,0,80,0);
    color:@black;
    .text-center;
    @media @mobile {
      .font-size(30);
      .line-height(40);
    }
  }
  .news-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media @tablet-portrait {
      justify-content: center;
    }
    @media @mobile {
      justify-content: center;
    }
    .item {
      .width(380);
      background: #f9f9f9;
      position: relative;
      .padding(0,0,56,0);
      border-bottom: 4px solid transparent;
      .margin(0,0,55,0);
      transition: all 0.3s ease;
      @media @tablet-portrait {
        margin-left: 1rem;
        margin-right: 1rem;
      }
      &:hover {
        background: @white;
        border-color:@primary;
        border-radius:0 0 1.250rem 1.250rem;
        box-shadow:0 7px 32px rgba(0,0,0,0.25);
      }
      .image {
        .width(367);
        .height(221);
        overflow: hidden;
        border-radius: 1.250rem;
        box-shadow:0 18px 27px rgba(0,0,0,0.2);
        .margin(-31,37,35,-22);
        a {
          display: block;
          height: 100%;
          transition: all 0.3s ease;
          background-position: 50% 50%;
          background-repeat: no-repeat;
          background-size: cover;
          &:hover,
          &:focus {
            transform: scale(1.1);
          }
        }
      }
      .inner {
        .padding(0,25,0,25);
        min-height: 7.5rem;
        h3 {
          .font-size(17.57);
          .line-height(29.29);
          font-weight: 700;
          margin: 0;
          a {
            color: @black;
            transition: all 0.3s ease;
            &:hover {
              text-decoration: none;
              color:@primary;
            }
          }
        }
      }
      .btm {
        position: absolute;
        bottom: 0;
        left:0;
        width:100%;
        .height(56);
        border-top:1px solid #e3e3e3;
        border-top: 1px solid #e3e3e3;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .padding(0,25,0,25);
        color: @secondary;
        .font-size(13);
        .line-height(16);
        .logo {
          max-height: 1.750rem;
          max-width: 6.5rem;
          height: auto;
          width: auto;
          margin: 0 1rem 0 0;
        }
        span {
          position: relative;
          .padding(0,5,0,0);
          .margin(0,4,0,0);
          &:last-child {
            padding: 0;
            margin: 0;
            &:after {
              display: none;
            }
          }
          &:after {
            content: '';
            background:@secondary;
            border-radius: 50%;
            width: 0.188rem;
            height: 0.188rem;
            position: absolute;
            right: 0;
            top: 0.438rem;
          }
        }
      }
    }
  }
}
/* news-block end */

/* contact-block start */
.contact-block {
  position: relative;
  .height(490);
  overflow: hidden;
  background-color: @secondary;
  background-image: url(../img/bg-lines.png);
  background-attachment: fixed;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: 50% 100%;
  @media @tablet-portrait, @mobile {
    height: auto;
    background-attachment: scroll;
  }
  .sub-logo {
    position: absolute;
    .width(248);
    height: auto;
    .top(90);
    left:8%;
    @media @tablet-portrait {
      left: 2%;
      width: 11.5rem;
    }
    @media @mobile {
      top: auto;
      bottom: 2rem;
      width: 6rem;
    }
  }
  .text {
    .padding(90,0,0,0);
    width:100%;
    .text-center;
    color:@white;
    .font-size(18);
    .line-height(28);
    @media @tablet-portrait {
      .padding(90,0,90,0);
    }
    @media @mobile {
      padding: 3rem 0 4rem;
    }
    h2 {
      .font-size(35);
      .line-height(40);
      font-weight: 700;
      .margin(0,0,22,0);
      @media @mobile {
        .font-size(30);
        .line-height(38);
      }
    }
    p {
      .margin(0,0,60,0);
      @media @mobile {
        margin: 0 0 2rem;
      }
    }
  }
}
/* contact-block end */