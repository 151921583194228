.about-page {
  .text-block {
    position: relative;
    overflow: hidden;
    .padding(85,0,85,0);
    .font-size(18);
    .line-height(36);
    color:@text;
    @media @mobile-xs {
      padding: 3rem 0;
    }
    &.gray-bg {
      background: #f9f9f9;
      .padding(80,0,40,0);
      @media @mobile-xs {
        padding: 3rem 0 2rem;
      }
    }
    .line-r-t {
      top:0;
      right:0;
      position: absolute;
      .width(264);
      @media @mobile-xs {
        width:10.5rem;
      }
    }
    .line-l-t {
      top:0;
      left:0;
      position: absolute;
      .width(392);
      @media @mobile-xs {
        width:11rem;
      }
    }
    .text-side {
      position: relative;
      z-index: 2;
    }
    .images-double {
      position: relative;
      z-index: 2;
      .width(400);
      @media @tablet {
        width:20rem;
      }
      @media @tablet-portrait, @mobile {
        width:20rem;
        margin: 0 auto 3.5rem;
      }
      @media @mobile-xs {
        margin-bottom: 10.5rem;
      }
      img {
        display: block;
        box-shadow:0 38px 42px rgba(0,0,0,0.3);
      }
      .img-1 {
        width:100%;
        height: auto;
      }
      .img-2 {
        .width(340);
        height: auto;
        .top(40);
        position: absolute;
        .left(358);
        @media @tablet, @mobile {
          width:15.25rem;
          left: 17.375rem;
        }
        @media @mobile-xs {
          left: 2.375rem;
          top: 12.5rem
        }
      }
    }
    .image-codes {
      float: right;
      position: relative;
      .width(812);
      height: auto;
      z-index: 2;
      @media @tablet, @mobile {
        width:100%;
      }
      img {
        width:100%;
        height: auto;
        position: absolute;
        left:0;
        top:0;
        &.img-empty {
          position: static;
        }
      }
    }
    .image-scheme {
      position: relative;
      .width(633);
      z-index: 2;
      @media @tablet, @mobile {
        width:100%;
      }
      img {
        width:100%;
        height: auto;
      }
    }
  }
}