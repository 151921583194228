.footer {
  background: #303336;
  .padding(18,0,18,0);
  color:#77797b;
  .footer-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media @mobile {
      flex-direction: column-reverse;
    }
  }
  .social {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .margin(0,0,0,0);
    @media @mobile {
      margin: 0 0 1rem;
    }
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      .width(35);
      .height(35);
      border-radius: 50%;
      color:#6b6b6b;
      .margin(0,0,0,14);
      border:2px solid #6b6b6b;
      transition: all 0.3s ease;
      &:hover,
      &:focus {
        text-decoration: none;
        background-color: #6b6b6b;
        color: #fff;
        transform: translate(0,-5px);
      }
      @media @mobile {
        .margin(0,7,0,7);
      }
      i {
        .font-size(16.2);
      }
      img {
        .width(19);
      }
    }
  }
  .copy {
    display: block;
    .font-size(14);
  }
}

